/**
 * App
 * @author: JS
 * @date: 01/2017
 **/

// Require
import $ from 'jquery';
import skrollr from 'skrollr';
// import Odometer from 'odometer';
import 'jquery-validation';
import Particles from 'particlesjs';

(() => {
  class App {
    constructor() {
      // Events
      $(document).ready(() => this.init());
      this.tabletMQ = matchMedia('only screen and (min-width: 48em)').matches;
    }

    init() {
      this.setupHeader();
      if($('.js-skrollr')[0]) {
        this.setupParallax();
      }
      this.setupResize();
      this.setupForm();
      // if($('.js-odometer')[0]) {
      //   this.setupOdometer();
      // }
      if($('.particles')[0]) {
        this.setupParticles();
      }
      this.setupPositionFooter();
      this.setupCookiesBanner();
      this.setupCloseBanner();
      this.setupGoToTop();
      this.setupFixedHeader();
    }

    setupFixedHeader() {

      $(window).scroll(function() {
        var windowST = $(window).scrollTop();
        var triggerHeader = $('.header').offset().top + 50;
        // console.log(windowST);
        // console.log(triggerHeader);

        if (windowST > triggerHeader){
          $('.header').addClass('header--static');
        }else if (triggerHeader == 50){
          $('.header').removeClass('header--static');
        }
      });
    }

    setupGoToTop() {
      $('.top-link').on('click', function(e) {
        e.preventDefault();
        $('html, body').animate({scrollTop: $('body').offset().top}, 1000);
      });
    }

    setupCloseBanner() {
      $('.btn--close-banner-msg').on('click', function(e){
        e.preventDefault();
        $('.banner-msg').toggle();
      })
    }

    setupCookiesBanner() {

      $('.btn-cookies-page').on('click', function(e) {
        e.preventDefault();

        var CookieDate = new Date;
        CookieDate.setFullYear(CookieDate.getFullYear( ) + 1);

        if ($('#acceptCGA').prop('checked')) {
          document.cookie = 'cookieAccepted=1;expires=' + CookieDate.toGMTString() + ';path=/';
        }
        else {
          document.cookie = 'cookieAccepted=0;expires=' + CookieDate.toGMTString() + ';path=/';
        }
        window.location.reload();
      });

      $('#js-accept-cookies').on('click', function(e) {
        e.preventDefault();

        var CookieDate = new Date;
        CookieDate.setFullYear(CookieDate.getFullYear( ) + 1);
        document.cookie = 'cookieAccepted=1;expires=' + CookieDate.toGMTString() + ';path=/';
        window.location.reload();
      });
    }

    setupPositionFooter() {
      var winH = $(window).height();
      var footerH = $('.footer').outerHeight();
      var footerPos = $('.footer').offset().top;

      if (winH > (footerPos+footerH)) {
        $('.footer').addClass('fixed');
      }else{
        $('.footer').removeClass('fixed');
      }
    }

    setupParticles() {
      Particles.init({
        selector: '.particles',
        color: ['#085FE4', '#00AFEE', '#0371E0'],
        // sizeVariations: '50',
        maxParticles: 100,
        speed: 0.5,
        connectParticles: true,
        responsive: [
          {
            breakpoint: 768,
            options: {
              maxParticles: 40
            }
          }
        ]
      });
    }

    setupForm() {
      // Animated labels
      $('body').on('focus', '.form__control, .form__textarea', function () {
        $(this).parent().addClass('active');
      })
      $('body').on('blur', '.form__control, .form__textarea', function () {
        var inputValue = $(this).val();
        if( inputValue == "" ) {
          $(this).parents('.form__group').removeClass('active');
        }
      })

      // Validate
      $('.form').validate({
        errorClass: 'form__error',
        highlight: function(element) {
          $(element).parent().addClass('has-error');
        },
        unhighlight: function(element) {
          $(element).parent().removeClass('has-error');
        }
      });
    }

    setupParallax() {
      const ua = window.navigator.userAgent.toLowerCase();
      let system = '';
      if (ua.indexOf('windows') >= 0) {
        system = 'Windows';
      }else if (ua.indexOf('mac') >= 0) {
        if (/ipad|iphone|ipod/.test(ua)) {
           system = 'iOS';
        }else {
           system = 'Mac OS';
        }
      }else if (ua.indexOf('android') >= 0) {
        system = 'Android';
      }else if (ua.indexOf('linux')) {
        system = 'Linux';
      }else {
        system = 'Inconnu';
      }
      if(this.tabletMQ) {
        if(system == "Windows" || system == "Linux" || system == "Mac OS"){
          skrollr.init();
        }else{
          skrollr.init().destroy();
        }
      }else {
        skrollr.init().destroy();
      }
    }

    setupHeader() {
      $('.js-toggle-nav').on('click', () => {
        $('body').toggleClass('show-nav');
      });

      $('.js-scroll-smooth').on('click', (e) => {
        e.preventDefault();
        $('html, body').animate({scrollTop: $($(e.currentTarget).attr('href')).offset().top}, 1000);
      });
    }

    setupResize() {
      $(window).on('resize', function () {
        this.setupParallax();
        this.setupPositionFooter();
      });
    }

    // setupOdometer() {
    //   var nbPresse = new Odometer({
    //     el: document.getElementById('nb-presse'),
    //     value: 0,
    //     format: 'd',
    //     theme: 'default'
    //   });
    //   var nbApp = new Odometer({
    //     el: document.getElementById('nb-apps'),
    //     value: 0,
    //     format: 'd',
    //     theme: 'default'
    //   });
    //   var nbCompany = new Odometer({
    //     el: document.getElementById('nb-company'),
    //     value: 0,
    //     format: 'd',
    //     theme: 'default'
    //   });
    //
    //   $(window).scroll(function() {
    //     var windowST = $(window).scrollTop();
    //     var triggerNumbers = $('.box-number').offset().top - $(window).height() + 200;
    //
    //     if (windowST > triggerNumbers){
    //       nbPresse.update(300);
    //       nbApp.update(500);
    //       nbCompany.update(260);
    //     }
    //   });
    // }
  }

  new App();
})();
